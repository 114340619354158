import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  Spacer,
  Spinner,
} from '@chakra-ui/react';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import { Company, useCompanyStore } from 'contexts/globalStoreCompanies';
import useLoaderStore from 'contexts/globalStore';
import { tableStorage } from 'contexts/tableStorage';
import useUserDataRolesStore from 'contexts/authStore';
import { checkIfUserCompanyCreator } from 'utils/roleHelpers';
import { getLocalizedLabel } from 'utils/translationHelpers';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = Company & {
  action: string;
};

export const SearchTableCompanies = memo(() => {
  const textColor = useColorModeValue('navy.700', 'white');

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const fetchPostosCompanies = useCompanyStore(
    (state: any) => state.fetchCompanies,
  );
  const { postosCompanies } = useCompanyStore(
    (state: any) => ({ postosCompanies: state.companies })
  );
  const { companiesMeta } = useCompanyStore(
    (state: any) => ({ companiesMeta: state.companiesMeta })
  );
  const companyStatusesLocal = useCompanyStore((state: any) => state.statusesLocal);

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const [data, setData] = useState<RowObj[]>(() => []);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const refreshData = useCallback(async () => {
    try {
      setLoadingState(true);
      await fetchPostosCompanies(
        pagination.pageIndex + 1,
        pagination.pageSize,
        sorting[0]?.id || null,
        sorting[0]?.desc ? 'desc' : 'asc'
      );
    } catch (error) {
      console.error('Error fetching Postos companies:', error);
    } finally {
      setLoadingState(false);
    }
  }, [fetchPostosCompanies,
    pagination.pageIndex,
    pagination.pageSize,
    setLoadingState,
    sorting]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(postosCompanies);
    }
    return () => {
      isCurrent = false;
    };
  }, [postosCompanies]);

  const navigate = useNavigate();

  const handleEditClick = useCallback((id: number) => {
    navigate(`/companies/edit/${id}`);
  }, [navigate]);

  const [globalFilter, setGlobalFilter] = useState('');
  const columnHelper = createColumnHelper<RowObj>();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="companies-table-header-name"
        >
          NAME
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        return (
            <Link
                color={textColor}
                data-test-id={`companies-table-cell-name-link-${id}`}
                to={`/companies/edit/${id}`}
            >
              <Text
                  color={textColor}
                  fontSize="md"
                  fontWeight="500"
                  _hover={{ textDecoration: 'underline' }}
              >
                {info.getValue()}
              </Text>
            </Link>
        );
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="companies-table-header-status"
        >
          {t('status', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`companies-table-cell-status-${id}`}
          >
            {getLocalizedLabel(companyStatusesLocal, info.getValue())}
          </Text>
        );
      },
    }),
    columnHelper.accessor('registration_number', {
      id: 'registration_number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="companies-table-header-registrationNumber"
        >
          {t('registrationNumber', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`companies-table-cell-registrationNumber-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor('uid', {
      id: 'uid',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="companies-table-header-uid"
        >
          UID
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`companies-table-cell-uid-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor('action', {
      id: 'action',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          paddingEnd={4}
          data-test-id="companies-table-header-actions"
        >
          {t('actions', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Flex justifyContent={'end'}>
            <Button
              cursor="pointer"
              variant="brand"
              id={info.row.original.id.toString()}
              data-test-id={`companies-table-cell-action-view-${id}`}
              onClick={() => handleEditClick(info.row.original.id)}
            >
              {t('view', { ns: ['actions'] }) + '/' + t('edit', { ns: ['actions'] })}
            </Button>
          </Flex>
        );
      },
    }),
  ];

  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    pageCount: companiesMeta.totalPages,
    rowCount: companiesMeta.totalItems,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const tableName = useMemo(() => 'companies', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName)

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, { globalFilter, pageSize: pagination.pageSize, sorting })
  }, [globalFilter, pagination.pageSize, sorting, tableName])

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        direction={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'flex-start', lg: 'flex-start' }}
        w="100%"
        px="22px"
        mb="36px"
        minWidth="max-content"
        alignItems="center"
        gap="4"
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className="font-lg border-block border p-2 shadow"
          placeholder={t('search', { ns: ['labels'] })}
          data-test-id="companies-table-filter-search"
        />

        <Spacer />
        {loadingState ? (
          <Spinner />
        ) : isUserCompanyCreator ? (
          <Button
            variant="brand"
            data-test-id="companies-table-filter-create"
            onClick={() => navigate('/companies/create') as any}
          >
            {t('create', { ns: ['actions'] })}
          </Button>
        ) : null}
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        data-test-id="companies-table"
      />
    </Flex>
  );
});

// A debounced input react component
//TODO DRY
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // Don't add onChange to dependencies, it causes infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, value]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="16px"
    />
  );
}

export default SearchTableCompanies;
